import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

const Page404 = () => (
  <Layout>
    <h1>404 - Page not found</h1>
    <p>Unfortunately we can't find the page you're looking for. It may have been moved or deleted.</p>
    <p>Please try returning to the <Link to="/">home page.</Link></p>
  </Layout>
);
export default Page404;